// ==============================
// * PAGE DESIGNER: TEXTBOX POSITION
//
// ? This file gathers all the custom textbox position CSS.
// ==============================

@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.textPosition {
    padding-top: 40px;
    padding-bottom: 40px;

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 20px;
    }

    p {
        max-width: 780px;
    }

    .btn {
        margin-top: 20px;
    }

    .center {
        text-align: center;

        p {
            margin: auto;
        }
    }

    .right {
        text-align: right;

        p {
            margin-left: auto;
        }
    }
}
